import styled from "styled-components";
import { BentoWrapper, BentoItem } from "../../BentoBox/BentoBox";
import { media, breakpoints } from "../../../styles/breakpoints";
import { typography } from "../../../styles/abstracts/typography";

export const ContactBento = styled(BentoWrapper)`
  grid-column: 2 /-1;
  grid-row: 2 /-1;
  padding: 0;
  align-items: center;
  grid-auto-flow: dense;
  padding: 0 5rem 0 5rem;
  column-gap: 2rem;
  display: flex;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 1 / -1;
    grid-row:auto;
    padding: 1rem;
    column-gap: 0.5rem;
  `}

  ${media.between("tabletL", "laptopS")`
    flex-direction: column;
  `}

  ${media.between("tabletS", "tabletL")`
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
    padding: 1rem;
    column-gap: 1rem;
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 2;
    grid-row: 2 / -1;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 1rem;
    column-gap: 1rem;
  `}

  ${media.down("mobileL")`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 1rem;
    column-gap: 1rem;
  `}
`;

export const ContactFormWrapper = styled(BentoItem)`
  grid-column: 1 / 5;
  grid-row: 2 / span 8;
  height: 100%;

  p,
  h2 {
    color: ${({ theme }) => theme.textColorLight};
  }

  p {
    font-site: ${typography.fontSizeMedium};
  }

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
    grid-column: 1 / span 5;
    grid-row: 1 /span 7;
    align-content: start;
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 1 / span 6;
    grid-row: 1 /span 7;
  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 1 / span 6;
    grid-row: 1 /span 8;
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: 1 /span 3;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: 1 /span 7;
  `}
`;

export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 2.75rem 1fr;
  grid-template-rows: 2.75rem 1fr;
  height: 90vh;
  pointer-events: auto;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height } = $viewport;
    const isLandscape = width > height;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-rows: ${isLandscape ? "2.75rem 1fr" : "3rem 1fr"};
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-rows: ${isLandscape ? "2.75rem 1fr" : "3rem 1fr"};
        grid-template-columns: 3rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: 3.5rem 1fr;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        padding: 0;
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 5.25rem 1fr 2.5rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 5.25rem 1fr 5.25rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 5.25rem 1fr 6.75rem;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 5.25rem 1fr 6.75rem;
      `;
    }
  }}
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
`;
